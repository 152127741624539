import React from 'react';
import Helmet from 'react-helmet'
import { withPrefix } from "gatsby"
import { HomepageCallout } from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import HomepageVideo from '../../components/HomepageVideo';

// Component to be shadowed

const FirstLeftText = () => (
  <p>
    Software <em>for traders</em> <br />
  </p>
);

const FirstRightText = () => (
  <p>
    <strong>Products and services</strong>
    <br />
    provided by actively trading developers
  </p>
);

const customProps = {
  Banner: (
    <HomepageVideo
      src="videos/hero-video-min.mp4"
      poster="images/hero-video-poster.jpg"
    />
  ),
  FirstCallout: (
    <HomepageCallout leftText={FirstLeftText} rightText={FirstRightText} backgroundColor='#343444' />
  ),
  SecondCallout: null,
  background: '#000000',
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return (
    <>
  <HomepageTemplate {...props} {...customProps} />
</>

  );
}

export default ShadowedHomepage;
