import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import HomepageTile from "../../../../src/components/HomepageTile";
import Carousel from "../../../../src/components/Carousel";
import ContactForm from "../../../../src/components/ContactForm";
import { HomepageCallout } from 'gatsby-theme-carbon';
import { navigate } from "gatsby";
import * as React from 'react';
export default {
  HomepageTemplate,
  HomepageTile,
  Carousel,
  ContactForm,
  HomepageCallout,
  navigate,
  React
};