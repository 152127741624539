import React, { useContext } from 'react';
import classnames from 'classnames';
import { SideNav, SideNavItems } from 'carbon-components-react';
import NavContext from 'gatsby-theme-carbon/src/util/context/NavContext';
import { useWindowSize } from 'gatsby-theme-carbon/src/util/hooks';
import { useNavItems } from '../../../../node_modules/gatsby-theme-carbon/src/components/LeftNav/LeftNavItemProvider';

import LeftNavItem from '../../../../node_modules/gatsby-theme-carbon/src/components/LeftNav/LeftNavItem';
import LeftNavResourceLinks from '../../../../node_modules/gatsby-theme-carbon/src/components/LeftNav/ResourceLinks';

import LeftNavWrapper from '../../../../node_modules/gatsby-theme-carbon/src/components/LeftNav/LeftNavWrapper';
import { sideNavDark } from '../../../../node_modules/gatsby-theme-carbon/src/components/LeftNav/LeftNav.module.scss';

const LeftNav = props => {
  const { leftNavIsOpen, toggleNavState } = useContext(NavContext);
  const windowSize = useWindowSize();

  //if (windowSize.innerWidth > 1056 && !leftNavIsOpen) {
  //  toggleNavState('leftNavIsOpen', 'open');
  //}

  const navItems = useNavItems();

  const renderNavItems = () =>
    navItems.map((item, i) => (
      <LeftNavItem items={item.pages} category={item.title} key={i} />
    ));

  // TODO: replace old addon website styles with sass modules, move to wrapper
  return (
    <LeftNavWrapper expanded={leftNavIsOpen}>
      <SideNav
        expanded
        defaultExpanded
        aria-label="Side navigation"
        className={classnames({
          [sideNavDark]: props.theme === 'dark' || props.homepage,
          'bx--side-nav--website': true,
          'bx--side-nav--website--dark':
            props.theme === 'dark' || props.homepage,
          'bx--side-nav--website--light':
            props.theme !== 'dark' && !props.homepage,
        })}
      >
        <SideNavItems>
          {renderNavItems()}
          <LeftNavResourceLinks />
        </SideNavItems>
      </SideNav>
    </LeftNavWrapper>
  );
};

export default LeftNav;
