// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-better-sounds-index-mdx": () => import("./../../../src/pages/blog/better-sounds/index.mdx" /* webpackChunkName: "component---src-pages-blog-better-sounds-index-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-blog-second-entry-strategy-index-mdx": () => import("./../../../src/pages/blog/second-entry-strategy/index.mdx" /* webpackChunkName: "component---src-pages-blog-second-entry-strategy-index-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-expertise-mdx": () => import("./../../../src/pages/expertise.mdx" /* webpackChunkName: "component---src-pages-expertise-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-ninjatrader-index-mdx": () => import("./../../../src/pages/ninjatrader/index.mdx" /* webpackChunkName: "component---src-pages-ninjatrader-index-mdx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-atm-whisperer-index-mdx": () => import("./../../../src/pages/products/atm-whisperer/index.mdx" /* webpackChunkName: "component---src-pages-products-atm-whisperer-index-mdx" */),
  "component---src-pages-products-second-entry-index-copy-mdx": () => import("./../../../src/pages/products/second-entry/index copy.mdx" /* webpackChunkName: "component---src-pages-products-second-entry-index-copy-mdx" */),
  "component---src-pages-products-second-entry-index-mdx": () => import("./../../../src/pages/products/second-entry/index.mdx" /* webpackChunkName: "component---src-pages-products-second-entry-index-mdx" */),
  "component---src-pages-products-second-entry-strategy-index-mdx": () => import("./../../../src/pages/products/second-entry-strategy/index.mdx" /* webpackChunkName: "component---src-pages-products-second-entry-strategy-index-mdx" */),
  "component---src-pages-products-sync-charts-index-mdx": () => import("./../../../src/pages/products/sync-charts/index.mdx" /* webpackChunkName: "component---src-pages-products-sync-charts-index-mdx" */),
  "component---src-pages-products-wizbot-index-mdx": () => import("./../../../src/pages/products/wizbot/index.mdx" /* webpackChunkName: "component---src-pages-products-wizbot-index-mdx" */),
  "component---src-pages-risk-js": () => import("./../../../src/pages/risk.js" /* webpackChunkName: "component---src-pages-risk-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-store-index-mdx": () => import("./../../../src/pages/store/index.mdx" /* webpackChunkName: "component---src-pages-store-index-mdx" */),
  "component---src-pages-store-paymentreceipt-js": () => import("./../../../src/pages/store/paymentreceipt.js" /* webpackChunkName: "component---src-pages-store-paymentreceipt-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

