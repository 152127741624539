/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'gatsby';
import { css } from "@emotion/core";
import { NetlifyForm, Honeypot, Recaptcha } from 'react-netlify-forms'
import Button from 'carbon-components-react/lib/components/Button';
import TextArea from 'carbon-components-react/lib/components/TextArea';
import TextInput from 'carbon-components-react/lib/components/TextInput';

///!!! SITE_RECAPTCHA_ ... must be in netlify IDE

class ContactForm extends React.Component {
  render() {
    const recaptchaKey = process.env.NODE_ENV==='development'?'zzz' : process.env.GATSBY_SITE_RECAPTCHA_KEY;
    
    return (
      <div>
        <div id="root"></div>
        <NetlifyForm
          name="Contact Form"
          action='/thanks'
          // honeypotName='bot-field'
          cache='no-cache'
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          enableRecaptcha
          onSuccess={(response, context) => {
            console.log('Successfully sent form data to Netlify Server')
            context.formRef.current.reset()
          }}>

          {({ handleChange, success, error }) => (
            <div>
              <Honeypot />
              <Recaptcha siteKey={recaptchaKey} invisible theme='dark' />
              <p 
                  style={{
                   display: 'hidden'
                  }}
                >
                </p>
              {success && (
                <p sx={{ variant: 'alerts.success', p: 3 }}>
                  Thanks for contacting us!
                </p>
              )}
              {error && (
                <p sx={{ variant: 'alerts.muted', p: 3 }}>
                  Sorry, we could not reach servers. Because it only works on Netlify,
                  our GitHub demo does not provide a response.
                </p>
              )}
              <div>
                <TextInput
                  className="some-class"
                  disabled={false}
                  helperText=""
                  id="fullname"
                  name="fullname"
                  invalid={false}
                  invalidText=""
                  // labelText="Full Name"
                  light={false}
                  onChange={handleChange}
                  onClick={function noRefCheck() {}}
                  placeholder="Full Name"
                  type="text"
                  required
                />
                <TextInput
                  className="some-class"
                  disabled={false}
                  helperText=""
                  id="companyname"
                  name="companyname"
                  invalid={false}
                  invalidText=""
                  // labelText="Company Name"
                  light={false}
                  onChange={handleChange}
                  onClick={function noRefCheck() {}}
                  placeholder="Company Name"
                  type="text"
                />
                <TextInput
                  className="some-class"
                  disabled={false}
                  helperText=""
                  id="phonenumber"
                  name="phonenumber"
                  invalid={false}
                  invalidText=""
                  // labelText="Text Input label"
                  light={false}
                  onChange={handleChange}
                  onClick={function noRefCheck() {}}
                  placeholder="Phone Number"
                  type="text"
                />
                <TextInput
                  className="some-class"
                  disabled={false}
                  helperText=""
                  id="email"
                  name="email"
                  invalid={false}
                  invalidText=""
                  placeholder="Email"
                  // labelText="Email"
                  light={false}
                  onChange={handleChange}
                  onClick={function noRefCheck() {}}
                  type="email"
                  required
                />
                <TextArea
                  className="some-class"
                  cols={50}
                  disabled={false}
                  helperText=""
                  id="message"
                  name="message"
                  invalid={false}
                  invalidText=""
                  // labelText="Message"
                  light={false}
                  onChange={handleChange}
                  onClick={function noRefCheck() {}}
                  placeholder="Message"
                  rows={4}
                />
                <p>
                  <hr/>
                  <br/>
                  By submitting this form I give my consent for Wizbord to process my
                  personal data pursuant to <Link to="/privacy">Privacy Policy</Link>.
                  <br/><br/>
                </p>
                <Button
                  className="contact-form-button"
                  disabled={false}
                  kind="primary"
                  tabIndex={0}
                  type="submit">
                  Submit
                </Button>
              </div>
            </div>
          )}
        </NetlifyForm>
      </div>
    );
  }
}

export default ContactForm;
