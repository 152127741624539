
import React, { useState } from "react"
import { Children } from "react"
import getStripe from "../../lib/stripejs"

const buttonStyles = {
  fontSize: "1rem",
  textAlign: "center",
  fontWeight: "bold",
  color: "#000",
  padding: "12px 10px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
  width: "100%",
}

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

const Checkout = ({ pageContext, lnk, ...rest }) => {
  const [loading, setLoading] = useState(false)

  return (
    <button
      disabled={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      //onClick={() => open("https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ENH826HFWMDF8", "_self")}
      onClick={() => open(lnk, "_self")}
    >
      {rest.children}
    </button>
  )
}

export default Checkout
